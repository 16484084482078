<template>
    <div class="report-content">
        <div class="filter-box">
            <div class="question-class">
                <div class="question-item">
                    <div class="text">班级名称</div>
                    <el-select class="W200-select" v-model="examClassValue" @change="handleChangeExam"
                               placeholder="请选择" clearable>
                        <el-option
                                v-for="item in examClassList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
<!--                <div class="question-item">-->
<!--                    <div class="text">考试时间</div>-->
<!--                    <el-date-picker-->
<!--                            v-show="this.dataNum ==0"-->
<!--                            v-model="pickDate"-->
<!--                            type="daterange"-->
<!--                            :picker-options="pickerOptions"-->
<!--                            start-placeholder="开始日期"-->
<!--                            end-placeholder="结束日期"-->
<!--                            format="yyyy-MM-dd"-->
<!--                            @change="chooseDate">-->
<!--                    </el-date-picker>-->
<!--                    <el-date-picker-->
<!--                            v-show="this.dataNum ==1"-->
<!--                            v-model="pickDate1"-->
<!--                            type="week"-->
<!--                            format="yyyy 第 WW 周"-->
<!--                            :picker-options="pickerOptions"-->
<!--                            placeholder="选择日期"-->
<!--                            @change="selectWeekDate">-->
<!--                    </el-date-picker>-->
<!--                    <el-date-picker-->
<!--                            v-show="this.dataNum ==2"-->
<!--                            v-model="pickDate2"-->
<!--                            type="month"-->
<!--                            :picker-options="pickerOptions"-->
<!--                            placeholder="选择日期"-->
<!--                            format="yyyy-MM"-->
<!--                            @change="selectMonthDate">-->
<!--                    </el-date-picker>-->
<!--                </div>-->
                <div class="question-item">
                    <el-select style="width:110px;margin-right: 10px;" v-model="pickerType"
                               @change="pickerTypeChange">
                        <el-option v-for="item in options" :key="item.key" :label="item.value" :value="item.key">
                        </el-option>
                    </el-select>
                    <el-date-picker style="width:150px" v-model="time1" :type="datePickerType" format="yyyy-MM-dd"
                                    placeholder="选择日期" @change="time1Change">
                    </el-date-picker>
                    <span style="margin: 0 5px;">&nbsp;&nbsp;-&nbsp;&nbsp;</span>
                    <el-date-picker style="width:150px" v-model="time2" readonly>
                    </el-date-picker>
                </div>
            </div>
        </div>
        <el-table class="customTable" :data="studentList" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="name" align="left" label="考试名称"></el-table-column>
            <el-table-column prop="num" align="center" label="考试人数"></el-table-column>
            <el-table-column prop="pass_rate" align="center" label="合格率"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="viewDetail(scope.row.exam_id)">查看详情</el-link>
                </template>
            </el-table-column>
        </el-table>
        <pagination class="pages-center" :total="total" @current-change="handleCurrentChange"/>
    </div>
</template>

<script>
    import pagination from "../../../components/pagination";
    import {schoolReportManagementList,getClassesList} from '@/utils/apis'

    export default {
        name: "Index",
        components: {
            pagination
        },
        data() {
            let self = this
            return {
                studentList: [],
                total: 0,
                currentPageNum: 1,
                examClassValue: '',
                examClassList: [],
                dataNum: 0,
                pickDate: '',
                pickDate1: '',
                pickDate2: '',
                dateTime: [],
                pickerOptions: {
                    shortcuts: [{
                        text: '按天查询',
                        onClick(picker) {
                            self.changeDataType(0);
                            picker.$emit('pick', '');
                        }
                    },
                        {
                            text: '按周查询',
                            onClick(picker) {
                                self.changeDataType(1);
                                picker.$emit('pick', '');
                            }
                        },
                        {
                            text: '按月查询',
                            onClick(picker) {
                                self.changeDataType(2);
                                picker.$emit('pick', '');
                            }
                        }
                    ]
                },
                time1: "",
                time2: "",
                datePickerType: "date",
                dataFormat: "yyyy-MM-dd",
                pickerType: "按天查询",
                options: [
                    {
                        key: "date",
                        value: "按天查询",
                    },
                    {
                        key: "week",
                        value: "按周查询",
                    },
                    {
                        key: "month",
                        value: "按月查询",
                    },
                ],
            }
        },
        mounted() {
            this.getStudentList();
            this.getExamList();
        },
        methods: {
            //时间筛选
            pickerTypeChange(e) {
                this.datePickerType = e;
                this.time1 = "";
                this.time2 = "";
            },
            time1Change(e) {
                this.currentPageNum = 1
                if(!e){
                    this.time1 = ''
                    this.time2 = ''
                    this.dateTime = []
                    this.getStudentList()
                    return
                }
                if (this.datePickerType === "date") {
                    this.time1 = this.dateFormatTwo(e.getTime())
                    this.time2 = this.dateFormatTwo(e.getTime() + 24 * 60 * 60 * 1000 - 1)
                    let startTime = this.time1
                    let endTime = this.time2
                    startTime = Date.parse(new Date(startTime)) / 1000;
                    endTime = Date.parse(new Date(endTime)) / 1000;
                    this.dateTime = [startTime, endTime];
                } else if (this.datePickerType === "week") {
                    this.time1 = this.dateFormatTwo(e.getTime() - 24 * 60 * 60 * 1000);
                    this.time2 = this.dateFormatTwo(e.getTime() + 5 * 86400000 + 24 * 60 * 60 * 1000 - 1);
                    let startTime = this.time1
                    let endTime = this.time2
                    startTime = Date.parse(new Date(startTime)) / 1000;
                    endTime = Date.parse(new Date(endTime)) / 1000;
                    this.dateTime = [startTime, endTime];
                } else {
                    this.time1 = e;
                    let year = e.getFullYear();
                    let month = e.getMonth() + 1;
                    let d = new Date(year, month, 0);
                    this.time2 = this.dateFormatTwo(
                        e.getTime() + 24 * 60 * 60 * 1000 * (d.getDate() - 1)
                    );
                    let date = new Date(e);
                    let startTime = this.dateFormatTwo(date);
                    let currentMonth = date.getMonth();
                    let nextMonth = ++currentMonth;
                    let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
                    let oneDay = 1000 * 60 * 60 * 24;
                    let endTimeGMT = new Date(nextMonthFirstDay - oneDay).getTime() + 24 * 60 * 60 * 1000 - 1;
                    let endTime = this.dateFormatTwo(endTimeGMT)
                    startTime = Date.parse(new Date(startTime)) / 1000;
                    endTime = Date.parse(new Date(endTime)) / 1000;
                    this.dateTime = [startTime, endTime];
                    this.dateTime = [startTime, endTime];
                }
                this.getStudentList()
            },
            getStudentList() {
                let param = {
                    page: this.currentPageNum,
                    paging: 1,
                    pageSize: 10
                }
                if (this.examClassValue !== '') {
                    param.class_id = this.examClassValue
                }
                if (this.dateTime.length > 0) {
                    param.begin_time = this.dateTime[0];
                    param.end_time = this.dateTime[1];
                }
                schoolReportManagementList(param).then(res => {
                    this.studentList = res.data.data
                    this.total = res.data.total
                }).catch(err => {
                    console.error(err)
                })
            },
            getExamList() {
                getClassesList().then((res) => {
                    this.examClassList = res.data
                })
            },
            viewDetail(id) {
                this.$router.push({
                    path: '/school/report/detail',
                    query: {
                        id: id
                    }
                })
            },
            handleCurrentChange(val) {
                this.currentPageNum = val;
                this.getStudentList();
            },
            handleChangeExam() {
                this.currentPageNum = 1
                this.getStudentList();
            },
            changeDataType(val) {
                this.dataNum = val;
            },
            chooseDate(val) {
                if (val !== '' && val !== null) {
                    let date = new Date(val[0]);
                    let date1 = new Date(val[1]);
                    let endTime = this.dateFormatTwo(date1.getTime() + 24 * 60 * 60 * 1000 - 1);
                    let startTime = this.dateFormatTwo(date);
                    startTime = Date.parse(new Date(startTime)) / 1000;
                    endTime = Date.parse(new Date(endTime)) / 1000;
                    this.dateTime = [startTime, endTime];
                } else {
                    this.dateTime = []
                }
                this.getStudentList();
            },
            selectWeekDate(val) {
                if (val !== '' && val !== null) {
                    let firstDay = new Date(val.getFullYear(), 0, 1); //2020-01-01
                    let dayOfWeek = firstDay.getDay();  //2020-01-01是周三  dayOfWeek==3
                    let spendDay = 1;
                    if (dayOfWeek != 0) {
                        spendDay = 7 - dayOfWeek + 1   // 5 离下周一还有5天
                    }
                    firstDay = new Date(val.getFullYear(), 0, 1 + spendDay);   // 2020-01-06是2020年第一个周一，是2020年第二个周
                    let d = Math.ceil((val.valueOf() - firstDay.valueOf()) / 86400000);   // 当前时间距离2020-01-06有几天
                    let result = Math.ceil(d / 7);   // 换算成周为单位 2020-01-06result是0,但其实是第二个周，所以默认加2
                    let startTime = this.dateFormatTwo(val.valueOf() - 86400000);   // 开始时间
                    let endTime = this.dateFormatTwo(val.valueOf() + 5 * 86400000 + 24 * 60 * 60 * 1000 - 1); //    结束时间
                    startTime = Date.parse(new Date(startTime)) / 1000;
                    endTime = Date.parse(new Date(endTime)) / 1000;
                    this.dateTime = [startTime, endTime];
                } else {
                    this.dateTime = []
                }
                this.getStudentList();
            },
            //选择月份
            selectMonthDate(val) {
                if (val !== '' && val !== null) {
                    let date = new Date(val);
                    let startTime = this.dateFormatTwo(date);
                    let currentMonth = date.getMonth();
                    let nextMonth = ++currentMonth;
                    let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
                    let oneDay = 1000 * 60 * 60 * 24;
                    let endTimeGMT = new Date(nextMonthFirstDay - oneDay).getTime() + 24 * 60 * 60 * 1000 - 1;
                    let endTime = this.dateFormatTwo(endTimeGMT)
                    startTime = Date.parse(new Date(startTime)) / 1000;
                    endTime = Date.parse(new Date(endTime)) / 1000;
                    this.dateTime = [startTime, endTime];
                } else {
                    this.dateTime = []
                }
                this.getStudentList();
            },
            dateFormatTwo: function (time) {
                let date = new Date(time);
                let year = date.getFullYear();
                let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
                let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
                let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
                // 拼接
                return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
            },
        }
    }
</script>

<style scoped lang="scss">
    .report-content {
        padding: 0 20px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .customTable {
            width: 100%;
            flex: 1;
            margin-top: 16px;
        }

        .filter-box {
            border-bottom: 1px solid #F6F9FF;
            padding-bottom: 16px;
            .question-class {
                display: flex;

                .question-item {
                    display: flex;
                    align-items: center;

                    &:last-child {
                        padding-left: 48px;
                    }

                    .w300-cascader {
                        width: 300px;
                    }

                    .W200-select {
                        width: 200px;
                    }

                    .text {
                        padding-right: 10px;
                    }
                }
            }
        }
    }
</style>